<template>
    <div class="wordsEvaluation flex-column-between">
        <div class="breadcrumb flex-align-between">
            <div class="PageHeader" v-if="$route.query.wordName && $route.query.className">
                当前位置：<span>{{ $route.query.className }}【{{ $route.query.wordName }}】的学习
                    <el-tooltip effect="light" placement="top-start">
                        <div slot="content">*[只读]已保存记录不支持上传新作品，<br />请点击“同步投影”或上课创建新的AI测评。</div>
                        <span>[只读]</span>
                    </el-tooltip>
                </span>
            </div>
            <div class="PageHeader" v-else>
                <span @click="goback">首页</span> / <span>投影与测评</span>
            </div>
            <div class="back flex-align-between">
                <img :src="require('../../assets/task/return.png')" @click="goback" />
                <span @click="goback">返回</span>
            </div>
        </div>
        <div class="mianbox flex-align-between" v-if="resource">
            <div class="mianImg">
                <!-- :options="options" -->
                <!-- @fatherMethod="report" -->
                <!-- @getChildInfo="getChildInfo" -->
                <Viewers ref="Viewers" :options="$route.query.type" :imgArr="imgArr" @lantern="lantern"
                    v-if="imgArr.length != 0">
                </Viewers>
                <div class="ReportPopup">
                    <el-dialog title="" :show-close="false" :visible.sync="ReportPopup" :close-on-click-modal="false"
                        center>
                        <!-- v-if="reportType" -->
                        <div class="mianshowreel">
                            <div class="Evaluationsubject" v-if="reportData.title != null">
                                【{{ reportData.title }}】作品测评报告
                            </div>
                            <div class="handleClose" @click="handleClose">
                                <img src="@/assets/showreel/Close.png" />
                            </div>
                            <div class="mianshowreelSon">
                                <div v-if="testStatus == 1" class="appraisalMianBox">
                                    <div class="evaluate mianSon">
                                        <div class="titlebox flex-align-between">
                                            <div class="flex-align">
                                                <img src="../../assets/assistant/pingjia.png" />
                                                <span class="titleText">综合评价</span>
                                            </div>
                                            <div class="flex-align">
                                                <span class="titleText">uploadId:{{ this.nowadayId }}</span>
                                                <span class="titleText" v-if="reportData.studentName">姓名:{{
                                                    reportData.studentName }}</span>
                                                <span class="titleText">时间:{{ formatTimeStamp(Date.now()) }}</span>
                                            </div>
                                        </div>

                                        <div class="flex-align">
                                            <div class="overallMerit">
                                                <div class="overallMerit_main">
                                                    <div class="container flex-align-center">
                                                        <div class="containerT">
                                                            <div class="figurebox flex-align-between">
                                                                <div class="figure" v-for="(item, index) in 3" :key="index">
                                                                    <img v-if="item == figureLevel[reportData.level]"
                                                                        src="../../assets/assistant/figure.png" />
                                                                </div>
                                                            </div>
                                                            <div class="progressBar"></div>
                                                            <div class="progressBarText flex-align-between">
                                                                <span style="color: #ffdb9c">待提升</span>
                                                                <span style="color: #f5aa49">良好</span>
                                                                <span style="color: #ed7e04">优秀</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="expositoryNotes">
                                                        <div class="expository_title">评价：</div>
                                                        <ul>
                                                            <li>
                                                                1.本幅作品整体达到<span class="textcolor">
                                                                    {{ figureName[reportData.level] }}等级
                                                                </span>，超过全国<span class="textcolor">
                                                                    {{ reportData.rankNumber }}% </span>的同学。
                                                            </li>
                                                            <li>
                                                                2.作品完整度为<span class="textcolor">
                                                                    {{ Math.round((reportData.writeCount * 100) /
                                                                        reportData.maxNum) }}%
                                                                </span>
                                                                ，{{ IntegrityText }} 卷面布局整齐度为<span class="textcolor">
                                                                    {{ Math.round(reportData.locationOffset - 0) }}%
                                                                </span>，{{ uniformityText }}
                                                            </li>
                                                            <li>
                                                                3.占格一致性<span class="textcolor">
                                                                    {{ Math.round(reportData.sizeOffset - 0) }}%
                                                                </span>，{{ AestheticsText }} 例字相似性为<span
                                                                    class="textcolor">{{ Math.round(reportData.scoreAvg - 0)
                                                                    }}%
                                                                </span>，{{ NormativeText }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="chapterEvaluation">
                                                <div id="myChart" class="Evaluationbox"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="appraisal mianSon">
                                        <div class="titlebox flex-align">
                                            <img src="../../assets/assistant/ceping.png" />
                                            <span class="titleText">整体测评</span>
                                            <span class="subheading">（点击单字即可查看单字点评）</span>
                                        </div>
                                        <div class="container flex-column-center">
                                            <div class="containerC flex-align-between">
                                                <div class="prev carousel" :class="{ prevAff: prevType }" @click="slidePre">
                                                </div>
                                                <div class="carouselMian">
                                                    <slider ref="slider" :options="sliderinit" @slide="slide">
                                                        <slideritem v-for="(item, index) in reportData.testList"
                                                            :key="index">
                                                            <div class="textbox flex-column-center"
                                                                :class="{ textSonAff: appraisalIindex == index, }"
                                                                @click="switchover(index)">
                                                                <div class="">
                                                                    <div class="textSonT">
                                                                        <img src="../../assets/assistant/tian.png" />
                                                                        <img
                                                                            :src="item.resFilename ? item.resFilename : item.imagePath" />
                                                                    </div>
                                                                    <div class="textSonImg flex-align-between">
                                                                        <div class="imgbox"
                                                                            v-for="(itemGrandson, indexGrandson) in starList[reportData.testList[index].level]"
                                                                            :key="indexGrandson">
                                                                            <img :src="itemGrandson" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </slideritem>
                                                    </slider>
                                                </div>
                                                <div class="next carousel"
                                                    :class="{ nextAff: nextType && this.reportData.testList.length > 5, }"
                                                    @click="slideNext"></div>
                                            </div>
                                            <div class="focus flex-align">
                                                <div :class="{ focusColor: focusIndex == index }"
                                                    v-for="(item, index) in focusNum" :key="index" @click="slideTo(index)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="remarkOn mianSon">
                                        <div class="titlebox flex-align">
                                            <img src="../../assets/assistant/remarkOn.png" />
                                            <span class="titleText">单字点评</span>
                                        </div>
                                        <div class="container flex-align-center">
                                            <div class="containerB flex-column-center">
                                                <div class="comparison flex-align-between"
                                                    v-if="reportData != '' && reportData.testList.length != 0">
                                                    <div class="wordBox flex-column-center">
                                                        <span>原字</span>
                                                        <div class="wordImg wordImgs" @click="amplification(0)">
                                                            <img src="../../assets/assistant/tian.png" />
                                                            <img :src="reportData.testList[appraisalIindex].wordCover" />
                                                        </div>
                                                    </div>
                                                    <div class="wordBox flex-column-center">
                                                        <span>待测字</span>
                                                        <div class="wordImg wordImgs" @click="amplification(1)">
                                                            <img src="../../assets/assistant/tian.png" />
                                                            <img v-if="reportData.testList[appraisalIindex].resFilename"
                                                                :src="reportData.testList[appraisalIindex].resFilename" />
                                                            <img v-else
                                                                :src="reportData.testList[appraisalIindex].imagePath" />
                                                        </div>
                                                    </div>
                                                    <div class="wordBox flex-column-center" @click="amplification(2)"
                                                        v-if="reportData.testList[appraisalIindex].wordType == 1">
                                                        <span>AI智能比对(拉伸后)</span>
                                                        <div class="wordImg wordImgs">
                                                            <img src="../../assets/assistant/tian.png" />
                                                            <img
                                                                :src="reportData.testList[appraisalIindex].wordShadowCover" />
                                                            <img :src="reportData.testList[appraisalIindex].imagePath" />
                                                            <img
                                                                :src="reportData.testList[appraisalIindex].wordSkillCover" />
                                                        </div>
                                                    </div>
                                                    <div class="wordBox flex-column-center"
                                                        @click="reportData.testList[appraisalIindex].wordType == 1 ? amplification(3) : amplification(2)"
                                                        v-if="reportData.testList[appraisalIindex].resFilename">
                                                        <span>AI智能比对(原作品)</span>
                                                        <div class="wordImg wordImgs"
                                                            v-if="reportData.testList[appraisalIindex].wordType == 1">
                                                            <img src="../../assets/assistant/tian.png" />
                                                            <img
                                                                :src="reportData.testList[appraisalIindex].wordShadowCover" />
                                                            <img :src="reportData.testList[appraisalIindex].resFilename" />
                                                            <img
                                                                :src="reportData.testList[appraisalIindex].wordSkillCover" />
                                                        </div>
                                                        <div class="wordImg wordImgs" v-else>
                                                            <img src="../../assets/assistant/tian.png" />

                                                            <img :src="reportData.testList[appraisalIindex].resFilename" />
                                                            <img
                                                                :src="reportData.testList[appraisalIindex].wordShadowCover" />
                                                            <img
                                                                :src="reportData.testList[appraisalIindex].wordSkillCover" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="remark"
                                                    v-if="reportData != '' && reportData.testList.length != 0 && reportData.testList[appraisalIindex].wordType == 1">
                                                    <span>评语</span>
                                                    <div class="remarkBox" v-if="reportData != ''">
                                                        <p v-html="reportData.testList[appraisalIindex].evaluateContent">
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="generated" v-else v-loading="loading" element-loading-text="测评生成中"
                                    element-loading-spinner="el-icon-loading"
                                    element-loading-background="rgba(255, 255, 255, 0.9)"></div>
                            </div>
                        </div>
                    </el-dialog>
                </div>
                <div class="qrcodeTip flex-align-between">
                    <div class="category flex-column-center">
                        <div class="flex-column-center" v-if="!$route.query.wordName && !$route.query.className">
                            <img :src="revealType ? require('../../assets/showreel/projectionB.png') : require('../../assets/showreel/projectionA.png')"
                                @click="qrcodeShow" />
                            <span @click="qrcodeShow">同步投影</span>
                        </div>
                        <div class="flex-column-center">
                            <img :src="appraisalType ? require('../../assets/showreel/appraisalB.png') : require('../../assets/showreel/appraisalA.png')"
                                @click="appraisalShow" />
                            <span @click="appraisalShow">AI测评</span>
                        </div>
                    </div>
                    <div class="qrcodeBox">
                        <div class="qrcodeIconDel">
                            <i class="el-icon-close" @click="revealType = false"></i>
                        </div>
                        <div class="qrcodeL" :class="{ qrcodeHidden: revealType }">
                            <div class="qrcodeDom flex-align">
                                <div class="qrcodeImg flex-align-center">
                                    <img src="@/assets/assistant/qrcode.jpg" />
                                </div>
                                <div class="qrcodetext flex-column">
                                    <span>微信扫描二维码，</span>
                                    <span>打开小程序</span>
                                </div>
                            </div>
                            <div class="text flex-align-center">
                                <div class="textGuidance flex-column-between">
                                    <div>教师登录“首师优字教师端小程序”后，</div>
                                    <div>
                                        点击
                                        <img src="../../assets/assistant/camera2.png" />同步投影使用。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="workR" :class="[{ workRAff: broadsideType }, { workRW: delStatus }]">
                <div class="packUp flex-align-center" @click="packUp">
                    <img :src="broadsideType ? img2 : img1" />
                </div>
                <div class="workImgList">
                    <div class="workImgbox flex-align-center" :class="{ workImgAff: viewerVal == index }"
                        v-for="(item, index) in imgArr" :key="index">
                        <img class="label label1"
                            :src="isIdentifyDoneType(item.isIdentifyDone, item.integrationLevel, item.version, item.writeCount)"
                            v-if="item.version == 2 && item.isIdentifyDone != 0 && item.integrationLevel != null" />
                        <img class="label label2"
                            :src="isIdentifyDoneType(item.isIdentifyDone, item.level, item.version, item.writeCount)"
                            v-if="item.version == 1 && item.status == 1" />
                        <div class="flex-column-center">
                            <div class="Img"
                                @click="pitchOn(index, item.id, item.status, item.isIdentifyDone, item.bindUserName, item.bindUserId, item.version, item.writeCount)">
                                <img :src="item.img2" />
                            </div>
                            <span style="color: #999999">{{ item.name }}</span>
                        </div>
                        <div class="delwords flex-column-center" v-if="delStatus">
                            <img src="@/assets/assistant/del.png" @click="
                                $route.query.type == 0
                                    ? deleteTestList(item.id, index)
                                    : deleteQQTestList(item.id, index)
                                " />
                            <span>删除</span>
                        </div>
                    </div>
                </div>
                <div class="workBtn flex-align-center" v-if="$route.query.type == 0">
                    <div class="flex-align-center" :class="{ workBtntype: workBtntype }" @click="Administration">
                        {{ workBtntype ? "取消" : "管理" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="projections" v-else>
            <!-- flex-align-center -->
            <div class="projectionText flex-column">
                <div class="hint">提示：</div>
                <div class="guidance flex-align-center">
                    <span>教师登录“首师优字教师端小程序”后，点击</span>
                    <img src="../../assets/assistant/camera2.png" />
                    <span>同步投影 使用</span>
                </div>
                <div class="qrcode flex-column-center">
                    <div class="qrcodeDom flex-align">
                        <div class="qrcodeImg flex-align-center">
                            <img src="@/assets/assistant/qrcode.jpg" />
                        </div>
                        <div class="qrcodetext flex-column">
                            <span>微信扫描二维码，</span>
                            <span>打开小程序</span>
                        </div>
                    </div>
                    <span class="tips">（注意：小程序和电脑端要使用同一账号）</span>
                </div>
            </div>
        </div>
        <div class="base"></div>
        <div class="commentImg">
            <!-- width="30%" -->
            <el-dialog :visible.sync="commenVisible" center :show-close="false" :modal-append-to-body="false">
                <div class="commentImgbox flex-align-between">
                    <div class="prev carousel" :class="{ prevAff: popupIndex == 0 }" @click="slidePrePop"></div>
                    <div class="classpopBox flex-align-center">
                        <div class="classpopBoxImg2 classpopBoxImg" v-if="popupIndex == 0">
                            <img src="../../assets/assistant/tian.png" />
                            <img :src="amplifier[0]" />
                        </div>
                        <div class="classpopBoxImg2 classpopBoxImg" v-if="popupIndex == 1">
                            <img src="../../assets/assistant/tian.png" />
                            <img :src="amplifier[1]" />
                        </div>
                        <div class="classpopBoxImg3 classpopBoxImg"
                            v-if="popupIndex == 2 && reportData.testList[appraisalIindex].wordType == 1">
                            <img :src="amplifier[2][0]" />
                            <img src="../../assets/assistant/tian.png" />
                            <img :src="amplifier[2][1]" />
                            <img :src="amplifier[2][2]" />
                        </div>
                        <div class="classpopBoxImg3 classpopBoxImg"
                            v-if="popupIndex == 2 && reportData.testList[appraisalIindex].wordType == 2">
                            <img :src="amplifier[2][1]" />
                            <img src="../../assets/assistant/tian.png" />
                            <img :src="amplifier[2][0]" />
                            <img :src="amplifier[2][2]" />
                        </div>


                        <div class="classpopBoxImg3 classpopBoxImg" v-if="popupIndex == 3">
                            <img :src="amplifier[3][0]" />
                            <img src="../../assets/assistant/tian.png" />
                            <img :src="amplifier[3][1]" />
                            <img :src="amplifier[3][2]" />
                        </div>
                    </div>
                    <div class="next carousel" :class="{ nextAff: popupIndex == this.amplifier.length - 1 }"
                        @click="slideNextPop"></div>
                    <div class="popClose" @click="commenVisible = false"></div>
                </div>
            </el-dialog>
        </div>
        <!-- 视频同步投影弹窗 -->
        <el-dialog custom-class="videoDialog" :visible.sync="dialogVisible" :close-on-click-modal="false"
            :show-close="false">
            <div class="polyvPlayer" id='player'></div>
        </el-dialog>
        <img class="close" @click="videoClose" v-if="dialogVisible" src="@/assets/copybook/close2.png" alt="">
        <!-- 退出投影弹窗 -->
        <el-dialog custom-class="exitDialog" :visible.sync="exitDialogVisible" :close-on-click-modal="false" center
            :title="dialogTitle">
            <div class="exitWrap flex-column" v-if="step == 1">
                <div class="workName flex-align">
                    <span>作品集名称</span>
                    <el-input v-model="inputVal" placeholder="请输入内容"></el-input>
                </div>
                <div class="className flex-align">
                    <span>授课班级</span>
                    <el-select v-model="classDefault" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="menu flex-layout">
                    <div class="goon" @click="goon">继续投影</div>
                    <div class="exit" @click="save">保存</div>
                </div>
            </div>
            <div class="success" v-else>
                <span>作品已保存至”作品集“，可打开</span>
                <span class="saveText"><span>”首页-作品集“</span>查看作品</span>
                <div class="know" @click="know">我知道了</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import Viewers from "../attendclass/ViewerRecord.vue";
import {
    slider,
    slideritem
} from "vue-concise-slider";
import moment from "moment";
export default {
    data() {
        return {
            imgArr: [],
            broadsideType: false,
            img1: require("../../assets/assistant/packUp1.png"),
            img2: require("../../assets/assistant/packUp2.png"),
            viewerVal: 0,
            nowadayId: "",
            testStatus: null,
            Underevaluation: require("../../assets/assistant/Underevaluation.png"),
            complete: require("../../assets/assistant/complete.png"),
            labels: {
                1: require("../../assets/assistant/Toimprove.png"),
                2: require("../../assets/assistant/Toimprove.png"),
                3: require("../../assets/assistant/well.png"),
                4: require("../../assets/assistant/well.png"),
                5: require("../../assets/assistant/excellent.png"),
            },
            newlabels: {
                1: require("../../assets/assistant/Toimprove.png"),
                2: require("../../assets/assistant/qualified.png"),
                3: require("../../assets/assistant/well.png"),
                4: require("../../assets/assistant/excellent.png"),
            },
            ReportPopup: false,
            //WebSocket
            websock: null, //建立的连接
            lockReconnect: false, //是否真正建立连接
            timeout: 5 * 1000, //20秒一次心跳
            timeoutObj: null, //心跳心跳倒计时
            serverTimeoutObj: null, //心跳倒计时
            timeoutnum: null, //断开 重连倒计时
            date: "",
            closeJunction: '{"msg":"心跳操作","code":209}',
            reportData: "",
            throttle: 0,
            resource: false,
            delStatus: false,
            workBtntype: false,
            appraisalIindex: 0,
            loading: false,
            figureLevel: {
                1: 1,
                2: 1,
                3: 2,
                4: 2,
                5: 3,
            },
            figureName: {
                1: "待提升",
                2: "待提升",
                3: "良好",
                4: "良好",
                5: "优秀",
            },
            prevType: false,
            //文字轮播
            sliderinit: {
                currentPage: 0,
                tracking: false,
                thresholdDistance: 100,
                thresholdTime: 300,
                infinite: 6,
                slidesToScroll: 6,
                freeze: true, //禁止拖动
            },
            starList: {
                1: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                2: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                3: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                4: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starA.png"),
                ],
                5: [
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                    require("../../assets/assistant/starB.png"),
                ],
            },
            prevType: false,
            nextType: false,
            amplifier: [
                [1],
                [1],
                [1, 2, 3]
            ],
            popupIndex: 0,
            commenVisible: false,
            revealType: false,
            appraisalType: false,
            indicatorValue: [],
            commentText: {
                0: [
                    "篇章完整，内容正确。",
                    "篇章较完整。",
                    "篇章不够完整，要正确的书写所有内容。",
                ],
                1: [
                    "布局协调，占格居中。",
                    "布局基本合理，注意重心位置。",
                    "布局不够整齐，注意上下左右保持对齐。",
                ],
                2: [
                    "字的大小适中，书写流畅。",
                    "书写大小基本均匀，内部空间不足。",
                    "字的书写偏差较大，整体偏大/小，主笔位置需找准。",
                ],
                3: [
                    "书写工整规范，运笔准确。",
                    "书写基本规范，结构需严谨。",
                    "书写不够规范，部件位置应准确。",
                ],
            },
            // 作品完整度
            IntegrityText: "",
            //布局整齐度
            uniformityText: "",
            //占格一致性
            AestheticsText: "",
            // 例字相似性
            NormativeText: "",
            focusNum: 0,
            focusIndex: 0,
            dialogVisible: false,
            exitDialogVisible: false,
            player: '',
            vodPlayerJs: 'https://player.polyv.net/script/player.js',
            options: [],
            classDefault: '',
            inputVal: '', //作品集名称
            showClassName: true,
            saveText: '保存并退出',
            vid: null,
            currentTime: null,
            playsafe: '', //解密凭证
            dialogTitle: '退出投影',
            step: 1,
            userStatus: true, // 身份状态 false == 401 身份过期      true 身份未过期
            versionAi: null,
            intervalId: null,
            bindUserName: null,
            bindUserId: null,
            writeCount: null,
        };
    },
    components: {
        Viewers,
        slider,
        slideritem,
    },
    //方法
    methods: {
        goback() {
            this.userStatus = false;
            if (this.$route.query.lessonId) {
                this.websocketclose();
                this.websock.send(this.closeJunction);
                this.$router.go(-1);
            } else {

                if (this.imgArr.length == 0) {
                    this.websocketclose();
                    this.websock.send(this.closeJunction);
                    if (window.history.length > 1) {
                        this.$router.go(-1); // 如果有上一页，返回上一页
                    } else {
                        window.close(); // 如果没有上一页，关闭当前页
                    }
                    // this.$router.go(-1);
                    // this.saveText = "退出";
                    // this.showClassName = true;
                } else {
                    this.exitDialogVisible = true;
                    this.saveText = "保存并退出";
                    this.showClassName = false;
                }
            }
        },
        // 继续投影
        goon() {
            this.exitDialogVisible = false;
        },
        // 保存并退出
        save() {
            // 调用保存接口，保存后退出
            // console.log('调用保存接口，保存后退出');
            if (!this.inputVal) {
                this.$message({
                    message: '请填写作品集名称',
                    type: 'warning'
                });
                return
            }
            if (!this.classDefault) {
                this.$message({
                    message: '请选择授课班级',
                    type: 'warning'
                });
                return
            }
            let data = {
                name: this.inputVal,
                classId: this.classDefault,
                lessonId: this.imgArr[0].lessonId
            }
            this.$Api.CopyBook.saveLiving(data)
                .then(res => {
                    // console.log('保存成功', res);
                    if (res.code == 200) {
                        this.dialogTitle = '保存成功';
                        this.step = 2;
                    }
                })
                .catch(err => {
                    console.log('保存失败', err)
                })
        },
        know() {
            this.websocketclose();
            this.websock.send(this.closeJunction);
            // this.$router.go(-1);
            if (window.history.length > 1) {
                this.$router.go(-1); // 如果有上一页，返回上一页
            } else {
                window.close(); // 如果没有上一页，关闭当前页
            }
        },
        // 退出
        exit() {
            this.imgArr = [];
            this.viewerVal = 0;
            this.exitDialogVisible = true;
            this.websocketclose();
            this.websock.send(this.closeJunction);

            // this.$router.go(-1);
            if (window.history.length > 1) {
                this.$router.go(-1); // 如果有上一页，返回上一页
            } else {
                window.close(); // 如果没有上一页，关闭当前页
            }
        },
        lantern(data) {
            data ? this.prev() : this.next();
        },
        //右侧作业列表收起切换
        packUp() {
            this.broadsideType = !this.broadsideType;
        },
        //点击右侧作业列表切换图片 -- 测试内容
        pitchOn(index, id, status, isIdentifyDone, bindUserName, bindUserId, version, writeCount) {
            this.appraisalIindex = 0;
            this.viewerVal = index;
            this.nowadayId = id;
            this.bindUserName = bindUserName;
            this.bindUserId = bindUserId;
            this.testStatus = status;
            this.isIdentifyDoneStatus = isIdentifyDone;
            this.versionAi = version;
            this.writeCount = writeCount;
            let that = this;
            this.$nextTick(() => {
                that.$refs.Viewers.Index = that.viewerVal;
            });
            this.inputVal = `同步投影-${this.imgArr[index].startTime}`
        },
        async deleteTestList(id, index) {
            let data = {
                ids: id,
            };

            let resData = await this.$Api.Myclass.deleteTestList(data);
            if (resData.code == 200) {
                this.$message({
                    message: "删除成功",
                    center: true,
                    type: "success",
                });
                this.IndexChange(index);
                this.imgArr.splice(index, 1);
                this.resource = this.imgArr.length == 0 ? false : true;
            }
        },
        async deleteQQTestList(id, index) {
            let data = {
                ids: id,
            };
            let resData = await this.$Api.Myclass.deleteQQTestList(data);
            if (resData.code == 200) {
                this.$message({
                    message: "删除成功",
                    center: true,
                    type: "success",
                });
                this.IndexChange(index);
                this.imgArr.splice(index, 1);
                this.resource = this.imgArr.length == 0 ? false : true;
            }
        },
        IndexChange(index) {
            if (index == this.imgArr.length - 1 && index == this.viewerVal) {
                this.imgArr.splice(index, 1);
                this.viewerVal = index - 1;
                let that = this;
                this.$nextTick(() => {
                    that.$refs.Viewers.Index = that.viewerVal;
                });
            }
        },
        // 获取身份信息
        // getUserInfo() {
        //     let that = this;

        // },
        //作品集管理
        Administration() {
            this.workBtntype = !this.workBtntype;
            this.delStatus = !this.delStatus;
        },
        //初始化 websocket
        initWebSocket() {
            //建立连接
            // let wsuri = `apitest.lezhireading.com/api/learn/socket/projection/video/${sessionStorage.getItem("teacherId")}`;
            let wsuri = `yunapi.cnuzi.com/api/learn/socket/projection/video/${sessionStorage.getItem("teacherId")}`
            //建立连接
            this.websock = new WebSocket("wss://" + wsuri);
            //连接成功
            this.websock.onopen = this.websocketonopen;
            //连接错误
            this.websock.onerror = this.websocketonerror;
            //接收信息
            this.websock.onmessage = this.websocketonmessage;
            //连接关闭
            // this.websock.onclose = this.websocketclose;
        },
        //重新连接 websocket
        reconnect() {
            var that = this;
            if (that.lockReconnect) {
                return;
            }
            that.lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            that.timeoutnum && clearTimeout(that.timeoutnum);
            that.timeoutnum = setTimeout(function () {
                //新连接
                that.initWebSocket();
                that.lockReconnect = false;
            }, 5000);
        },
        //重置心跳
        reset() {
            var that = this;
            //清除时间
            clearTimeout(that.timeoutObj);
            clearTimeout(that.serverTimeoutObj);
            //重启心跳
            that.start();
        },
        //开启心跳
        start() {
            var self = this;
            self.timeoutObj && clearTimeout(self.timeoutObj);
            self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);

            self.timeoutObj = setInterval(function () {
                //这里发送一个心跳，后端收到后，返回一个心跳消息
                // console.log('self.websock.readyState', self.websock.readyState)
                self.$Api.Home.getUserInfo().then((resData) => {
                    // console.log('身份状态', resData)
                    if (resData.status == 401 || !self.userStatus) {
                        // that.userStatus = false;
                        clearTimeout(self.timeoutObj);
                        clearTimeout(self.serverTimeoutObj);
                        return;
                    }
                    if (self.websock.readyState == 1) {
                        // console.log('self.date', self.date)
                        // self.websock.send(self.date);
                        // self.websocketsend();
                        self.websock.send(JSON.stringify({
                            name: "heartbeat",
                            code: 201
                        }));
                    } else {
                        // if (!self.userStatus) {
                        //     clearTimeout(self.timeoutObj);
                        //     clearTimeout(self.serverTimeoutObj);
                        //     return;
                        // }
                        //否则重连
                        self.reconnect();
                        // self.serverTimeoutObj = setTimeout(function () {
                        //   //超时关闭
                        //   self.websock.close();
                        // }, self.timeout);
                    }

                });
            }, self.timeout);
        },
        //websocket 连接成功回调
        websocketonopen() {
            // console.log('websocket连接成功')
            //连接成功事件
            this.websocketsend();
            //提示成功
            //开启心跳
            this.start();
        },
        //websocket 连接失败回调
        websocketonerror(e) {
            // console.log('websocket连接失败', e)
            //连接失败事件
            //错误
            //重连
            // this.reconnect();
        },
        //websocket 连接关闭回调
        websocketclose(e) {
            // console.log('websocket连接关闭')
            //连接关闭事件
            //提示关闭
            //重连
            // this.reconnect();
            clearInterval(this.timeoutObj);
            this.timeoutObj = null;
            // if (this.websock && this.websock.readyState !== WebSocket.CLOSED) {
            //     this.websock.close();  // 确保关闭连接
            //     // 111111
            // }
        },
        //websocket 接收服务器推送的信息
        websocketonmessage(event) {
            let data = JSON.parse(event.data);
            console.log('//接收服务器推送的信息', data)
            if (this.throttle == 0 && data.data.code == 1002) {
                this.getWordImg();
                this.throttle = 1;
            }
            if (data.data.code == 1001) {
                this.getWordImg();
            }
            if (data.code == 1003) {
                this.destroyed();
                this.vid = JSON.parse(data.data.code).code;
                this.currentTime = JSON.parse(data.data.code).currentTime
                this.dialogVisible = true;
                this.loadPlayer();
            }
            // data.code = 200;
            //收到服务器信息，心跳重置
            this.reset();
        },
        //websocket 发送信息
        websocketsend() {
            // console.log('websocket发送信息')
            let msg = {
                code: 2001,
                data: JSON.stringify(this.$route.query),
            };
            // this.date = JSON.stringify(msg);
            //向服务器发送信息
            // console.log('//向服务器发送信息', JSON.stringify(msg))
            this.websock.send(JSON.stringify(msg));
        },
        //学生作品列表
        async getWordImg() {
            let data = {
                wordId: '',
                lessonId: this.$route.query.lessonId,
                socketType: 2,
            };
            let resData = await this.$Api.Myclass.queryTeaUploadWorksList(data);
            // console.log('resData--', resData)

            if (resData.data.length == 0) {
                this.imgArr = [];
                this.resource = false;
                return;
            }

            this.resource = true;
            this.imgArr = [];
            for (let i = 0; i < resData.data.length; i++) {
                // let angle =
                //     resData.data[i].params != null ?
                //     JSON.parse(resData.data[i].params).deg :
                //     0;
                this.imgArr.push({
                    img1: resData.data[i].filePath,
                    img2: resData.data[i].thumbFilePath,
                    name: resData.data[i].bindUserName,
                    id: resData.data[i].id,
                    bindUserName: resData.data[i].bindUserName,
                    bindUserId: resData.data[i].bindUserId,
                    level: resData.data[i].level,
                    integrationLevel: resData.data[i].integrationLevel,
                    status: resData.data[i].status,
                    // deg: angle,
                    L: null,
                    T: null,
                    multiples: 1,
                    score: resData.data[i].score,
                    startTime: resData.data[i].startTime,
                    lessonId: resData.data[i].lessonId,
                    isIdentifyDone: resData.data[i].isIdentifyDone,
                    version: resData.data[i].version,
                    writeCount: resData.data[i].writeCount,
                    deg: 0
                });
            }
            // this.imgArr = this.imgArr.reverse();
            // let name = this.formatTimeStamp(new Date(this.imgArr[0].startTime).getTime())
            this.inputVal = `同步投影-${this.imgArr[0].startTime}`
            if (this.resource) this.estimate();
        },
        //判断当前索引
        estimate() {
            let that = this;
            let result = this.imgArr.findIndex((item) => {
                return item.id == that.nowadayId;
            });
            if (result != -1) {
                this.viewerVal = result;
            } else if (this.viewerVal > this.imgArr.length - 1) {
                this.viewerVal = this.imgArr.length - 1;
            }
            this.nowadayId = this.imgArr[this.viewerVal].id;
            this.bindUserName = this.imgArr[this.viewerVal].bindUserName;
            this.bindUserId = this.imgArr[this.viewerVal].bindUserId;
            this.testStatus = this.imgArr[this.viewerVal].status;
            this.isIdentifyDoneStatus = this.imgArr[this.viewerVal].isIdentifyDone;
            this.versionAi = this.imgArr[this.viewerVal].version;
            this.writeCount = this.imgArr[this.viewerVal].writeCount;
            if (this.resource) {
                setTimeout(() => {
                    that.$nextTick(() => {
                        that.$refs.Viewers.Index = that.viewerVal;
                    });
                }, 1)
            }
        },
        //学生测评报告
        async getreport() {
            let that = this;
            let data = {
                uploadId: this.nowadayId,
            };
            let resData = await this.$Api.Myclass.getTeachAssessReport(data);
            this.reportData = resData.data;
            if (this.reportData.testList.length != 0) {
                this.spreadPdf(0);
            }
            this.indicatorValue = [
                Math.round((this.reportData.writeCount * 100) / this.reportData.maxNum),
                Math.round(this.reportData.locationOffset - 0),
                Math.round(this.reportData.sizeOffset - 0),
                Math.round(this.reportData.scoreAvg - 0),
            ];
            this.focusNum = Math.ceil(this.reportData.testList.length / 6);
            this.IntegrityText =
                this.commentText[0][this.comments(this.indicatorValue[0])];
            this.uniformityText =
                this.commentText[1][this.comments(this.indicatorValue[1])];
            this.AestheticsText =
                this.commentText[2][this.comments(this.indicatorValue[2])];
            this.NormativeText =
                this.commentText[3][this.comments(this.indicatorValue[3])];
            setTimeout(() => {
                that.drawRadar1();
            }, 1000);
        },
        //个人测评报告
        async getAssessReport() {
            let that = this;
            let data = {
                uploadId: this.nowadayId,
                type: 3,
            };
            let resData = await this.$Api.Myclass.getAssessReport(data);
            this.reportData = resData.data;
            if (this.reportData.testList.length != 0) {
                this.spreadPdf(0);
            }
            this.indicatorValue = [
                Math.round((this.reportData.writeCount * 100) / this.reportData.maxNum),
                Math.round(this.reportData.locationOffset - 0),
                Math.round(this.reportData.sizeOffset - 0),
                Math.round(this.reportData.scoreAvg - 0),
            ];
            this.focusNum = Math.ceil(this.reportData.testList.length / 6);
            this.IntegrityText =
                this.commentText[0][this.comments(this.indicatorValue[0])];
            this.uniformityText =
                this.commentText[1][this.comments(this.indicatorValue[1])];
            this.AestheticsText =
                this.commentText[2][this.comments(this.indicatorValue[2])];
            this.NormativeText =
                this.commentText[3][this.comments(this.indicatorValue[3])];
            setTimeout(() => {
                that.drawRadar1();
            }, 1);
        },
        //向父组件图片放大弹窗传递数据
        spreadPdf(value) {
            let spread;
            if (this.reportData.testList[value].resFilename) {
                if (this.reportData.testList[this.appraisalIindex].wordType == 1) {
                    spread = [
                        [this.reportData.testList[value].wordCover],
                        [this.reportData.testList[value].resFilename ? this.reportData.testList[value].resFilename : this.reportData.testList[value].imagePath],
                        [
                            this.reportData.testList[value].wordShadowCover,
                            this.reportData.testList[value].imagePath,
                            this.reportData.testList[value].wordSkillCover,
                        ],
                        [
                            this.reportData.testList[value].wordShadowCover,
                            this.reportData.testList[value].resFilename,
                            this.reportData.testList[value].wordSkillCover,
                        ],
                    ]
                } else {
                    spread = [
                        [this.reportData.testList[value].wordCover],
                        [this.reportData.testList[value].resFilename ? this.reportData.testList[value].resFilename : this.reportData.testList[value].imagePath],
                        [
                            this.reportData.testList[value].wordShadowCover,
                            this.reportData.testList[value].resFilename,
                            this.reportData.testList[value].wordSkillCover,
                        ],
                    ]
                }

            } else {
                spread = [
                    [this.reportData.testList[value].wordCover],
                    [this.reportData.testList[value].resFilename ? this.reportData.testList[value].resFilename : this.reportData.testList[value].imagePath],
                    [
                        this.reportData.testList[value].wordShadowCover,
                        this.reportData.testList[value].imagePath,
                        this.reportData.testList[value].wordSkillCover,
                    ],
                ]
            }
            this.amplifier = spread;
            // console.log('this.amplifier', this.amplifier);

        },
        //图片放大
        amplification(index) {
            this.commenVisible = true;
            this.popupIndex = index;
        },
        prev() {
            if (this.viewerVal > 0) {
                this.viewerVal--;
                this.pitchOn(
                    this.viewerVal,
                    this.imgArr[this.viewerVal].id,
                    this.imgArr[this.viewerVal].status,
                    this.imgArr[this.viewerVal].isIdentifyDone,
                    this.imgArr[this.viewerVal].bindUserName,
                    this.imgArr[this.viewerVal].bindUserId,
                    this.imgArr[this.viewerVal].version,
                );
            }
        },
        next() {
            if (this.viewerVal < this.imgArr.length - 1) {
                this.viewerVal++;
                this.pitchOn(
                    this.viewerVal,
                    this.imgArr[this.viewerVal].id,
                    this.imgArr[this.viewerVal].status,
                    this.imgArr[this.viewerVal].isIdentifyDone,
                    this.imgArr[this.viewerVal].bindUserName,
                    this.imgArr[this.viewerVal].bindUserId,
                    this.imgArr[this.viewerVal].version,
                );
            }
        },
        slidePrePop() {
            if (this.popupIndex > 0) {
                this.popupIndex--;
            }
        },
        slideNextPop() {
            if (this.popupIndex < (this.amplifier.length == 4 ? 3 : 2)) {
                this.popupIndex++;
            }
        },
        handleClose() {
            this.ReportPopup = false;
            this.appraisalType = false;
            this.focusNum = 0;
            this.focusIndex = 0;
            this.appraisalIindex = 0;
            this.$refs.slider.$emit("slideTo", 0);
        },
        //测评切换
        report() {
            console.log('this.testStatus:',this.testStatus)
            if (this.testStatus == 0) {
                this.$confirm(`报告生成中请稍后再试(uploadId:${this.nowadayId})`, "提示", {
                    confirmButtonText: "确定",
                    type: "success",
                    center: true,
                    showCancelButton: false,
                })
                    .then(() => {
                        this.appraisalType = false;
                    })
                    .catch(() => {
                        this.appraisalType = false;
                    });
            } else if (this.testStatus == 1) {
                this.reportType = true;
                this.appraisalType = false;
                if (this.versionAi == 2) {
                    // if (this.isIdentifyDoneStatus == 0) {
                    //     this.$confirm("报告生成中请稍后再试", "提示", {
                    //         confirmButtonText: "确定",
                    //         type: "success",
                    //         center: true,
                    //         showCancelButton: false,
                    //     })
                    //         .then(() => {
                    //             this.appraisalType = false;
                    //         })
                    //         .catch(() => {
                    //             this.appraisalType = false;
                    //         });
                    // } else {
                        this.appraisalType = false;
                        let routeData = this.$router.resolve({
                            path: '/examPaperTest',
                            query: {
                                reportId: this.nowadayId,
                                bindUserName: this.bindUserName,
                                bindUserId: this.bindUserId,
                                versionAi: this.versionAi,
                                writeCount: this.writeCount,
                            }
                        })
                        window.open(routeData.href, '_blank');
                    // }
                } else {
                    this.ReportPopup = true;
                    this.getreport();
                    this.loading = false;
                }
            } else if (this.testStatus == 2) {
                this.$confirm(`报告生成失败,请联系管理员(uploadId:${this.nowadayId})`, "提示", {
                    confirmButtonText: "确定",
                    type: "success",
                    center: true,
                    showCancelButton: false,
                })
                    .then(() => {
                        this.appraisalType = false;
                    })
                    .catch(() => {
                        this.appraisalType = false;
                    });
            } else if (this.testStatus == 3) {
                this.$confirm(`该内容非本课作品，请检查后重新提交(uploadId:${this.nowadayId})`, "提示", {
                    confirmButtonText: "确定",
                    type: "success",
                    center: true,
                    showCancelButton: false,
                })
                    .then(() => {
                        this.appraisalType = false;
                    })
                    .catch(() => {
                        this.appraisalType = false;
                    });
            } else if (this.testStatus == 4) {
                this.$confirm(`未识别到要测评的生字，请重新拍摄(uploadId:${this.nowadayId})`, "提示", {
                    confirmButtonText: "确定",
                    type: "success",
                    center: true,
                    showCancelButton: false,
                })
                    .then(() => {
                        this.appraisalType = false;
                    })
                    .catch(() => {
                        this.appraisalType = false;
                    });
            } else if (this.testStatus == 5) {
                this.$confirm(`<span style="font-size:16px">测评不成功(uploadId:${this.nowadayId})，请检查是否满足以下条件：<br/>1.系统只支持带有二维码的作品进行测评；<br/>2.作品需完整（包含外边框）、清晰、无损坏。<span/>`, "提示", {
                    confirmButtonText: "我知道了",
                    type: "success",
                    center: true,
                    showCancelButton: false,
                    dangerouslyUseHTMLString: true,
                    customClass: 'customClass'
                })
                    .then(() => {
                        this.appraisalType = false;
                    })
                    .catch(() => {
                        this.appraisalType = false;
                    });
            } else if (this.testStatus == 6) {
                this.$confirm("该字暂不支持测评", "提示", {
                    confirmButtonText: "确定",
                    type: "success",
                    center: true,
                    showCancelButton: false,
                })
                    .then(() => {
                        this.appraisalType = false;
                    })
                    .catch(() => {
                        this.appraisalType = false;
                    });
            }
        },
        permission() {
            const schoolId = sessionStorage.getItem('schoolId');
            const gradeId = sessionStorage.getItem('gradeId');
            return schoolId === '181195' || (schoolId === '181077' && ['3', '4'].includes(gradeId));
        },
        // 角标状态
        isIdentifyDoneType(type, level, version, writeCount) {
            if (version == 1) {
                if (writeCount == 0) {
                    return this.complete;
                } else {
                    return this.labels[level];
                }

            } else {
                if (writeCount == 0) {
                    return this.complete;
                } else {
                    if (type == 1) {
                        return this.Underevaluation;
                    } else if (type == 2) {
                        return this.newlabels[level];
                    }
                }

            }

        },
        //测评
        slide(data) {
            this.focusIndex = Math.ceil(data.currentPage / 6);
            if (data.currentPage == 0) {
                this.nextType = true;
                this.prevType = false;
            } else if (data.currentPage > this.reportData.testList.length - 6) {
                this.nextType = false;
                this.prevType = true;
            } else {
                this.nextType = true;
                this.prevType = true;
            }
        },
        //测试上下一页
        slideNext() {
            this.$refs.slider.$emit("slideNext");
        },
        //测试上一页
        slidePre() {
            this.$refs.slider.$emit("slidePre");
        },
        //整体测评点击切换
        switchover(index) {
            this.appraisalIindex = index;
            this.spreadPdf(index);
        },
        qrcodeShow() {
            this.revealType = !this.revealType;
            // this.resource = false;
        },
        appraisalShow() {
            this.appraisalType = !this.appraisalType;
            let that = this;
            this.report();
        },
        contains(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i].name === obj) {
                    return i;
                }
            }
            return false;
        },
        drawRadar1() {
            this.$echarts.init(document.getElementById("myChart")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("myChart"));
            let indicator = [{
                name: "作品完整度",
                max: 100
            },
            {
                name: "布局整齐度",
                max: 100
            },
            {
                name: "占格一致性",
                max: 100
            },
            {
                name: "例字相似性",
                max: 100
            },
            ];
            let dataArr = [{
                value: that.indicatorValue,
            },];
            myChart.setOption({
                radar: {
                    radius: "60%",
                    name: {
                        formatter: (value) => {
                            let i = that.contains(indicator, value);
                            return (
                                "{b|" +
                                value +
                                "}\n" +
                                "{a|" +
                                that.indicatorValue[i] +
                                "%" +
                                "}"
                            );
                        },
                        rich: {
                            a: {
                                align: "center",
                                color: "#E77903",
                                fontSize: 11,
                            },
                            b: {
                                align: "center",
                                color: "#151515",
                                fontSize: 11,
                                height: 20,
                            },
                        },
                    },

                    nameGap: 8,
                    indicator: indicator,
                    splitLine: {
                        show: false,
                    },
                    splitArea: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
                series: [{
                    type: "radar",
                    symbolSize: 10,
                    symbol: "none",
                    lineStyle: {
                        width: 2,
                        color: "#E77903",
                    },
                    data: dataArr,
                    areaStyle: {
                        color: "#E77903",
                        opacity: 0.3,
                    },
                },],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        comments(value) {
            let index = value <= 60 ? 2 : value >= 80 ? 0 : 1;
            return index;
        },
        slideTo(value) {
            this.$refs.slider.$emit("slideTo", value * 6);
        },
        //  ------ 1.9需求新增 ------
        videoClose() {
            this.dialogVisible = false;
            this.destroyed()
        },
        loadPlayerScript(callback) {
            if (!window.polyvPlayer) {
                const myScript = document.createElement('script');
                myScript.setAttribute('src', this.vodPlayerJs);
                myScript.onload = callback;
                document.body.appendChild(myScript);
            } else {
                callback();
            }
        },
        loadPlayer() {
            this.$nextTick(() => {
                const polyvPlayer = window.polyvPlayer;
                this.player = polyvPlayer({
                    wrap: '#player',
                    vid: this.vid,
                    watchStartTime: this.currentTime,
                    autoplay: true
                });
            })

        },
        destroyed() {
            if (this.player) {
                this.player.destroy();
            }
        },
        // 保存投影作品获取班级列表
        getClassList() {
            this.$Api.CopyBook.getClassList()
                .then(res => {
                    this.options = res.data;
                    this.classDefault = res.data[0].id
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 格式化时间方法
        formatTimeStamp(timestamp, format) {
            if (!timestamp) {
                return 0;
            }
            let formatTime;
            const formatStr = format || 'yyyy-MM-dd hh:mm:ss';
            const date = new Date(timestamp);
            const o = {
                'M+': date.getMonth() + 1, // 月份
                'd+': date.getDate(), // 日
                'h+': date.getHours(), // 小时
                'm+': date.getMinutes(), // 分
                's+': date.getSeconds(), // 秒
            };
            if (/(y+)/.test(formatStr)) {
                formatTime = formatStr.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
            } else {
                formatTime = formatStr;
            }
            for (const k in o) {
                if (new RegExp(`(${k})`).test(formatTime)) {
                    formatTime = formatTime.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(
                        `${o[k]}`
                            .length));
                }
            }
            return formatTime;
        },
    },
    created() { },
    mounted() {
        this.initWebSocket()
        this.getClassList();
    },
    beforeDestroy() {
        // 组件销毁前清除定时器
        clearInterval(this.intervalId);
    },
};
</script>
<style lang='less' scoped>
@import "./less/syncProjection.less";

.carouselMian {
    height: 140px;
    width: 810px;

    /deep/.slider-wrapper {
        margin: 0 2px;
    }

    /deep/.slider-item {
        width: 112.4px !important;
        height: 135px !important;
        margin-right: 26px;
    }

    /deep/.slider-pagination {
        display: none;
        // bottom: 0px;
    }

    /deep/.swiper-container-horizontal>*>.slider-pagination-bullet {
        width: 20px !important;
        height: 8px !important;
        border-radius: 14px;
        background-color: #c2c2c2;
    }

    /deep/.swiper-container-horizontal .slider-pagination-bullet-active {
        background-color: #89dcc9;
    }
}
</style>